import React, { Component } from "react";
const $ = require("jquery");

const categories = [
    {
        name: "food",
        icon: () => (
            <svg
                id="Iconspace_Hamburger_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M5.5,21a4.5,4.5,0,0,1-4.484-4.122A3.991,3.991,0,0,0,2,17H17a4.008,4.008,0,0,0,.984-.122A4.5,4.5,0,0,1,13.5,21ZM11,10h3.586l-1.793,1.793Zm7-1H.5A.5.5,0,0,1,0,8.5,8.51,8.51,0,0,1,8.5,0h2A8.51,8.51,0,0,1,19,8.5a.5.5,0,0,1-.5.5Z"
                    transform="translate(3 2)"
                    fill="#da4b0d"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M10.586,0l3.207,3.207L17,0h1a3,3,0,0,1,0,6H3A3,3,0,0,1,3,0Z"
                    transform="translate(2 12)"
                    fill="#ffba33"
                />
            </svg>
        ),
        text: "Yemek"
    },
    {
        name: "toy",
        icon: () => (
            <svg
                id="iconspace_Controller_3_25px"
                data-name="iconspace_Controller 3_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <rect id="Path" width="25" height="25" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M6.326,7.25H0A3.507,3.507,0,0,1,2.413,5.331V0h1.5V5.331A3.507,3.507,0,0,1,6.326,7.25Z"
                    transform="translate(9.337 7.75)"
                    fill="#ff8753"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M3.5,7A3.5,3.5,0,1,1,7,3.5,3.5,3.5,0,0,1,3.5,7Z"
                    transform="translate(9 2)"
                    fill="#002c9d"
                />
                <path
                    id="Path-4"
                    data-name="Path"
                    d="M2.5,0h16A2.5,2.5,0,0,1,21,2.5v3A1.5,1.5,0,0,1,19.5,7H1.5A1.5,1.5,0,0,1,0,5.5v-3A2.5,2.5,0,0,1,2.5,0Z"
                    transform="translate(2 16)"
                    fill="#002c9d"
                />
            </svg>
        ),
        text: "Oyuncak"
    },
    {
        name: "diamond",
        icon: () => (
            <svg
                id="iconspace_Diamond_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="path-2"
                    data-name="path"
                    d="M0,1.023H5.715L9.758,16Zm14.843,0h5.693L10.856,15.88Zm-8.091,0h7.054l-3.516,13.1ZM15.043,0h0ZM6.476,0h0ZM.267,0h0Z"
                    transform="translate(2.233 7)"
                    fill="#e6c619"
                />
                <path
                    id="path-3"
                    data-name="path"
                    d="M.28,6h0Zm20.5-.541a.388.388,0,0,1,0,.079ZM15.137,5l2.6-4.522a.382.382,0,0,0,.026-.05L20.561,5ZM7.28,5l3-3.962L13.268,5ZM0,5,2.787.445l.018.033L5.409,5ZM10.725,0h6.134L14.215,4.627ZM3.684,0H9.833l-3.5,4.627Z"
                    transform="translate(2.221 2)"
                    fill="gold"
                />
            </svg>
        ),
        text: "Takı ve Mücevher"
    },
    {
        name: "dress",
        icon: () => (
            <svg
                id="iconspace_Dress_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M1.5,21A1.5,1.5,0,0,1,0,19.5a1.476,1.476,0,0,1,.047-.372L2.533,9.407c.029-.113.054-.229.075-.344l1.469.49a12.834,12.834,0,0,0,4.846,0l1.445-.481a5.339,5.339,0,0,0,.139.729l2.444,9.319A1.5,1.5,0,0,1,11.5,21ZM4.393,8.6,2.687,8.035a4.982,4.982,0,0,0-.348-1.7L1.878,5.171a2.492,2.492,0,0,1-.152-1.258L2.2.432A.5.5,0,0,1,2.7,0H4.22A.5.5,0,0,1,4.7.363,1.876,1.876,0,0,0,7.02,1.651,1.877,1.877,0,0,0,8.309.363.5.5,0,0,1,8.789,0h1.523a.5.5,0,0,1,.5.432l.48,3.513a2.492,2.492,0,0,1-.126,1.188l-.507,1.4a5.492,5.492,0,0,0-.314,1.49L8.607,8.6a8.884,8.884,0,0,1-2.107.18A8.893,8.893,0,0,1,4.393,8.6Z"
                    transform="translate(6 2)"
                    fill="#790f59"
                />
            </svg>
        ),
        text: "Kadın Giyim"
    },
    {
        name: "shoes",
        icon: () => (
            <svg
                id="iconspace_Classing_Shoes_25px"
                data-name="iconspace_Classing Shoes_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M10.334,3.194,8.869.733a1.5,1.5,0,0,0-2.633.1L6.052,1.2A1.931,1.931,0,0,1,3.6,2.137L2.061,1.517A1.5,1.5,0,0,0,0,2.908V7.481a1.5,1.5,0,0,0,1.5,1.5H7.891a1.5,1.5,0,0,1,.836.255l1.988,1.336A2.5,2.5,0,0,0,12.109,11H19.5A1.5,1.5,0,0,0,21,9.5V8.985a2.536,2.536,0,0,0-1.8-2.428L11.759,4.308A2.5,2.5,0,0,1,10.334,3.194Z"
                    transform="translate(2 7)"
                    fill="#2b3e43"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M6.921.5A1.5,1.5,0,0,1,5.5,1.519h-4A1.5,1.5,0,0,1,0,.019V0A2.489,2.489,0,0,0,1.5.5Z"
                    transform="translate(2 16.481)"
                    fill="#7bb9d2"
                />
            </svg>
        ),
        text: "Ayakkabı"
    },
    {
        name: "holiday",
        icon: () => (
            <svg
                id="iconspace_Google_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M17.9,4.01H19.25v1.5H17.974A5,5,0,0,1,9,8,5,5,0,1,1,5,0h8a5,5,0,0,1,4.9,4.01Z"
                    transform="translate(2 4)"
                    fill="#3866a6"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M3.5,18.63v1.12a.25.25,0,0,0,.25.25h6A2.25,2.25,0,0,0,12,17.75V.75a.75.75,0,0,1,1.5,0v17A3.75,3.75,0,0,1,9.75,21.5h-6A1.75,1.75,0,0,1,2,19.75V18.63a2.751,2.751,0,0,1-2-2.646.75.75,0,0,1,1.5,0,1.25,1.25,0,0,0,2.5,0,.75.75,0,0,1,1.5,0,2.751,2.751,0,0,1-2,2.646Z"
                    transform="translate(8.75 1.516)"
                    fill="#7ea5dd"
                />
            </svg>
        ),
        text: "Tatil"
    }
];

export class Categories extends Component {
    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    render() {
        return (
            <div className="row row-deck gutters-0">
                {categories.map((el, key) => {
                    return (
                        <div className="col-lg-2 col-md-6 col-sm-6" key={key.toString()}>
                            <div
                                className={`card rounded-0 hover-${el.name} cursor-pointer`}
                                data-toggle="tooltip"
                                title={el.text}>
                                <div className="card-body p-3 text-center">
                                    <span className="category-icon mr-2">{el.icon()}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Categories;
