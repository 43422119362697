import React, { Component } from "react";
import logo from "../../assets/images/new-gifts4me.png";

export class HeaderMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            getCart: (JSON.parse(localStorage.getItem("cart-detail")) || []).length
        };

        setInterval(
            () => this.setState({ getCart: (JSON.parse(localStorage.getItem("cart-detail")) || []).length }),
            200
        );
    }

    render() {
        const { getCart } = this.state;
        return (
            <div className="header py-4">
                <div className="container">
                    <div className="d-flex flex-column flex-sm-row">
                        <a className="header-brand mr-0" href="/app/home">
                            <img src={logo} alt="gifts4.me" width="140" />
                        </a>
                        <div className="ml-sm-auto mt-2 mt-sm-0 d-flex align-items-center">
                            <span id="offerForm"></span>
                            <span id="contactModal"></span>
                            <span id="bultenPopup"></span>
                            <a className="btn btn-icon text-decoration-none btn-link" href="/app/cart">
                                <i className="fa fa-shopping-bag mr-1"></i> Listeye Git ({getCart})
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeaderMenu;
