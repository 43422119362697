import React, { Component } from "react";
import Banner from "../../components/includes/Banner";
import { ProductDetail } from "../../services/Request";
import { Toast } from "../../components/Alert";
import { currencyFormat } from "../../assets/js/core";

export class Products extends Component {
    constructor(props) {
        super(props);
        const url_split = decodeURIComponent(this.props.location.pathname)
            .replace("/app/product/", "")
            .split("://");
        const url_splitted = url_split[url_split.length > 1 ? 1 : 0];
        const clean_params = url_splitted.split("?")[0];
        console.log(clean_params);
        this.state = {
            productUrl: clean_params,
            productDetail: null
        };
    }

    componentDidMount() {
        this.productDetail();
    }

    componentWillReceiveProps() {
        window.location.reload();
    }

    productDetail = () => {
        const { productUrl } = this.state;
        ProductDetail(productUrl).then(res => this.setState({ productDetail: res.data[0] }));
    };

    addToCart = () => {
        const { productDetail } = this.state;
        const getCart = JSON.parse(localStorage.getItem("cart-detail")) || [];

        const productInfo = {
            Product: productDetail.Product,
            Picture: productDetail.Picture,
            Price: productDetail.Price,
            FirstPrice: productDetail.FirstPrice,
            Referer: productDetail.Referer,
            Brand: productDetail.Brand,
            Currency: productDetail.Currency,
            Host: productDetail.HostName
        };

        getCart.push(productInfo);
        localStorage.setItem("cart-detail", JSON.stringify(getCart));

        Toast.fire({
            icon: "success",
            title: "Listeye Eklendi!"
        });
    };

    render() {
        const { productDetail } = this.state;
        return (
            <div>
                <Banner mini />
                <div className="container mt-6">
                    <div className="card">
                        {productDetail && Object.keys(productDetail).length > 0 ? (
                            <div className="card-body">
                                <input type="hidden" name="hostName" id="hostName" value={productDetail.HostName} />
                                <input type="hidden" name="cartValue" id="cartValue" value={productDetail.Cart} />
                                <input type="hidden" name="cartValue" id="hitValue" value={productDetail.Hit} />
                                <input type="hidden" name="cartValue" id="invoiceValue" value={productDetail.Invoice} />
                                <input type="hidden" name="stockCount" id="stockCount" value={productDetail.Stock} />
                                <input
                                    type="hidden"
                                    name="Category"
                                    id="Category"
                                    value={productDetail.Category.replace("https://", "").replace("http://", "")}
                                />
                                <input
                                    type="hidden"
                                    name="discountRate"
                                    id="discountRate"
                                    value={productDetail.DiscountRate}
                                />
                                <div className="row">
                                    <div className="col-lg-12">
                                        <img
                                            src="https://img-armagan.mncdn.com/mnmresize/1200/56/media/mnmcms/widgetThirtyNine/2510bakugan1200x56-5db91d67d9cbf.jpg"
                                            alt=""
                                        />
                                        <div id="productPageBannerArea"></div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-6 col-md-5">
                                        <div className="border text-center rounded-sm">
                                            <img
                                                id="productImage"
                                                src={productDetail.Picture}
                                                alt={productDetail.Product}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-7 pt-5">
                                        <a
                                            data-referer={productDetail.HostName}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={"https://" + productDetail.HostName}
                                            className="h5 py-1 px-2 rounded-sm bg-info  text-white text-uppercase text-decoration-none"
                                            id="customerName">
                                            {productDetail.HostName}
                                        </a>
                                        <div className="text-gray mt-3" id="brandName">
                                            {productDetail.Brand}
                                        </div>
                                        <h2 className="mb-0" id="productName">
                                            {productDetail.Product}
                                        </h2>
                                        <div className="text-muted mb-5">
                                            Ürün Kodu:&nbsp;
                                            <span id="productId">{productDetail.ProductID || "—"}</span>
                                        </div>

                                        <div className="row">
                                            {parseFloat(productDetail.FirstPrice) &&
                                            parseFloat(productDetail.DiscountRate) ? (
                                                <div className="col-auto">
                                                    <div className="bg-green-light text-white text-center px-5 py-3">
                                                        <div
                                                            className="font-weight-600 h2 mb-0"
                                                            id="discountPersentage">
                                                            %{productDetail.DiscountRate.split(".")[1]}
                                                        </div>
                                                        <span className="h3 text-uppercase">İNDİRİM</span>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="col">
                                                <div className="h2 mb-0 text-muted text-line-through" id="firstPrice">
                                                    {parseFloat(productDetail.FirstPrice)
                                                        ? parseFloat(productDetail.FirstPrice).format() +
                                                          " " +
                                                          currencyFormat[productDetail.Currency.trim() || "TL"]
                                                        : null}
                                                </div>
                                                <div className="display-4 text-orange font-weight-600" id="price">
                                                    {parseFloat(productDetail.Price).format()}{" "}
                                                    {currencyFormat[productDetail.Currency.trim() || "TL"]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row gutters-xs mt-5">
                                            <div className="col-lg-6 col-md-6 mb-2">
                                                <a
                                                    href={
                                                        productDetail.Referer.indexOf("http") > -1
                                                            ? productDetail.Referer
                                                            : "https://" + productDetail.Referer
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="btn btn-lg btn-icon font-weight-600 btn-outline-danger btn-block">
                                                    <i className="fa fa-store mr-1"></i> Mağazaya Git
                                                </a>
                                            </div>
                                            {/* <div className="col-lg-6 col-md-6 mb-2">
                                                        <button className="btn btn-lg btn-icon font-weight-600 btn-outline-success btn-block">
                                                            <i className="fa fa-money-bill-wave mr-1"></i> Sipariş Ver
                                                        </button>
                                                    </div> */}
                                            <div className="col-lg-6 col-md-6 mb-2">
                                                <button
                                                    onClick={this.addToCart}
                                                    className="btn btn-lg btn-icon font-weight-600 btn-outline-primary btn-block">
                                                    <i className="fa fa-shopping-bag mr-1"></i> Listeme Ekle
                                                </button>
                                            </div>
                                            {/* <div className="col-lg-6 col-md-6 mb-2">
                                                <button className="btn btn-lg btn-icon font-weight-600 btn-outline-warning btn-block">
                                                    <i className="fa fa-bullhorn mr-1"></i> Haberdar Et
                                                </button>
                                            </div> */}
                                        </div>
                                        {productDetail.Stock === 1 ? null : (
                                            <>
                                                <div className="p-4 text-muted text-center h4 font-italic">
                                                    Üzgünüz, bu ürünün stoğu tükenmiştir...
                                                </div>
                                            </>
                                        )}
                                        <div id="shareProduct"></div>
                                    </div>
                                </div>
                            </div>
                        ) : productDetail === undefined ? (
                            <div className="card-body text-center">
                                <p className="text-muted font-italic h3 font-weight-normal">
                                    Ürün bilgisi yüklenemedi...
                                </p>
                                <button onClick={this.slUpdate} className="btn btn-primary btn-sm">
                                    Yeniden Dene
                                </button>
                            </div>
                        ) : (
                            <div className="card-body text-center">
                                <div className="loader mx-auto"></div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container">
                    <div id="recommendProductsForCategory"></div>
                    <div id="popularProductsForCategory"></div>
                    <div id="personalHistory"></div>
                </div>
            </div>
        );
    }
}

export default Products;
