import React, { Component } from "react";
import { withRouter } from "react-router-dom";

const categories = [
    {
        name: "Perspective",
        category_id: "1",
        category: "Kadın Giyim",
        url: "perspective.com.tr",
        vm_i: "17",
        vm_c: "2140",
        vm_id: "2121"
    },
    {
        name: "Lohusam",
        category_id: "1",
        category: "Kadın Giyim",
        url: "lohusam.com",
        vm_i: "2",
        vm_c: "1961",
        vm_id: "1961"
    },
    {
        name: "Armağan Oyuncak",
        category_id: "2",
        category: "Oyuncak",
        url: "armaganoyuncak.com.tr",
        vm_i: "287",
        vm_c: "1701",
        vm_id: "1701"
    },
    {
        name: "Bunlardan İstiyorum",
        category_id: "2",
        category: "Oyuncak",
        url: "bunlardanistiyorum.com",
        vm_i: "4",
        vm_c: "1466",
        vm_id: "1466"
    },
    {
        name: "Kırtasiye Dünyası",
        category_id: "3",
        category: "Kırtasiye",
        url: "kirtasiyedunyasi.com",
        vm_i: "1",
        vm_c: "2010",
        vm_id: "2010"
    },
    {
        name: "Gülaylar",
        category_id: "4",
        category: "Takı - Mücevher",
        url: "gulaylar.com",
        vm_i: "273",
        vm_c: "1776",
        vm_id: "1776"
    },
    {
        name: "May Gold",
        category_id: "4",
        category: "Takı - Mücevher",
        url: "maygold.com.tr",
        vm_i: "281",
        vm_c: "2032",
        vm_id: "2032"
    },
    {
        name: "Bal Parmak",
        category_id: "5",
        category: "Gıda",
        url: "balparmak.com.tr",
        vm_i: "292",
        vm_c: "2079",
        vm_id: "2079"
    },
    {
        name: "Destekten",
        category_id: "6",
        category: "Sağlık",
        url: "destekten.com",
        vm_i: "1",
        vm_c: "1652",
        vm_id: "1652"
    },
    {
        name: "Marka Farma",
        category_id: "6",
        category: "Sağlık",
        url: "markafarma.com",
        vm_i: "1",
        vm_c: "1773",
        vm_id: "1773"
    },
    {
        name: "1881 İstanbul",
        category_id: "7",
        category: "Sanat - Müze",
        url: "istanbul1881.com",
        vm_i: "1",
        vm_c: "1301",
        vm_id: "1301"
    },
    {
        name: "İstanbul Modern",
        category_id: "7",
        category: "Sanat - Müze",
        url: "istanbulmodern.org",
        vm_i: "297",
        vm_c: "607",
        vm_id: "607"
    }
];

export class ProductList extends Component {
    render() {
        console.log(this.props);
        const cid = this.props.match.params.cid;
        return (
            <div className="container">
                <div className="row">
                    <div className="page-header d-none" id="productList-header">
                        <h1 className="page-title">
                            <strong>{categories.find(x => x.category_id === cid).category}</strong> Kategorisinin
                            Ürünleri
                        </h1>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div id="productList"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProductList);
