import "./assets/css/dashboard.css";
import "./assets/css/custom.css";
import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap.bundle";
import React from "react";
import ReactDOM from "react-dom";
import Core from "./layouts/Core.jsx";
import * as serviceWorker from "./serviceWorker";
const $ = require("jquery");

/** Initialize popovers */
$(function() {
    $('[data-toggle="popover"]').popover({
        html: true,
        trigger: "hover"
    });
});

$(function() {
    $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
    const script = document.createElement("script");
    script.src = "https://storage.googleapis.com/valuezon/scripts/d.js";
    script.setAttribute("id", "valuezon-tags");
    script.setAttribute("data-id", "1133");
    script.setAttribute("data-i", "380");
    script.setAttribute("data-c", "1133");
    script.setAttribute("defer", "defer");
    script.async = true;
    script.onload = function() {
        var clearTarget = setInterval(() => {
            if (window.target_sl) {
                localStorage.setItem("target_sl", window.target_sl);
                clearInterval(clearTarget);
            }
        }, 100);
    };

    document.body.appendChild(script);
});

const rootElement = document.getElementById("root");

ReactDOM.render(<Core />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
