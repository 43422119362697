import React, { Component } from "react";
import Banner from "../../components/includes/Banner";
import MiniCategories from "../../components/Home/MiniCategories";
import { currencyFormat } from "../../assets/js/core";
import _ from "lodash";

export class Carts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cartDetail: JSON.parse(localStorage.getItem("cart-detail")) || []
        };
    }

    renderCart = () => {
        const { cartDetail } = this.state;
        if (cartDetail.length > 0) {
            return (
                <table
                    id="cartProduts"
                    className="table table-outline table-hover table-vcenter text-nowrap card-table">
                    <tbody>
                        {cartDetail.map((el, key) => {
                            return (
                                <tr
                                    key={key.toString()}
                                    onClick={() => this.props.history.push("/app/product/" + el.Referer)}
                                    className="cartProductList cursor-pointer">
                                    <td className="text-center">
                                        <img
                                            id="cartProductPicture"
                                            src={el.Picture}
                                            alt={el.Product}
                                            className="h-9"
                                        />
                                    </td>
                                    <td>
                                        <a
                                            data-referer={el.Host}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={"https://" + el.Host}
                                            className="text-uppercase badge badge-info"
                                            id="cartProductCustomerName">
                                            {el.Host}
                                        </a>
                                        <div id="cartProductBrand" className="text-muted">
                                            {el.Brand}
                                        </div>
                                        <a
                                            id="cartProductProduct"
                                            href={"/app/product/" + el.Referer}
                                            className="text-interit text-decoration-none h4 mb-0 font-weight-600">
                                            {el.Product}
                                        </a>
                                    </td>
                                    <td>
                                        <div className="text-muted text-line-through" id="cartProductFirstPrice">
                                            {parseFloat(el.FirstPrice)
                                                ? parseFloat(el.FirstPrice).format() +
                                                  " " +
                                                  currencyFormat[el.Currency.trim() || "TL"]
                                                : null}
                                        </div>
                                        <div className="h3 text-green-light mb-0 font-weight-600" id="cartProductPrice">
                                            {parseFloat(el.Price).format()} {currencyFormat[el.Currency.trim() || "TL"]}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else {
            return (
                <div className="text-center p-5">
                    <p className="text-center font-weight-normal font-italic h4 mb-3 text-muted">
                        Listen boş görünüyor, sana özel ürünleri hemen keşfet...
                    </p>
                    <a href="/app/home" className="btn btn-purple btn-icon">
                        <i className="fa fa-compass mr-1"></i>Haydi Keşfedelim!
                    </a>
                </div>
            );
        }
    };

    render() {
        const { cartDetail } = this.state;
        return (
            <div>
                <Banner mini />
                <div className="container mt-6">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="h2">Listem</div>
                            <div className="card">
                                <div className="table-responsive">{this.renderCart()}</div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-12">
                            <div className="h2">Özet</div>
                            <div className="card">
                                <div className="card-body text-right">
                                    <div className="small text-muted">Toplam Ürün</div>
                                    <div className="h4">{cartDetail.length} ürün(ler)</div>
                                    <div className="small text-muted">Ödenecek Tutar</div>
                                    <div className="h1">
                                        {parseFloat(_.sumBy(cartDetail, x => parseFloat(x.Price))).format()}
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <a href="/app/success" className="btn btn-success btn-lg font-weight-600 btn-block">
                                        TAMAMLA
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Carts;
