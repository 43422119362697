import React, { Component } from "react";
import Banner from "../../components/includes/Banner";

export class Home extends Component {
    render() {
        return (
            <>
                <Banner mini />
                <div className="container">
                    <div id="dailyDeal"></div>
                    <div id="popularProducts"></div>
                    <div id="recommendProducts"></div>
                    <div id="category-popular-1"></div>
                    <div id="category-popular-2"></div>
                    <div id="category-popular-3"></div>
                    <div id="category-popular-4"></div>
                    <div id="category-popular-5"></div>
                    <div id="category-popular-6"></div>
                    <div id="category-popular-7"></div>
                    <div id="category-popular-8"></div>
                    <div id="category-popular-9"></div>
                    <div id="category-popular-10"></div>
                    <div id="personalHistory"></div>
                </div>
            </>
        );
    }
}

export default Home;
