import Home from "../views/Home/Home";
import Category from "../views/Category/Category";
import Products from "../views/Products/Products";
import Search from "../views/Search/Search";
import Demo from "../views/Demo/Demo";
import Carts from "../views/Carts/Carts";
import Success from "../views/Success/Success";
import _404 from "../views/Pages/404";

var indexRoutes = [
    {
        path: "/home",
        name: "Homepage",
        component: Home,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "/category/:cid",
        name: "Category",
        component: Category,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "/product/**",
        name: "Products",
        component: Products,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "/search(/:q)?",
        name: "Products",
        component: Search,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "/cart",
        name: "Carts",
        component: Carts,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "/success",
        name: "Success",
        component: Success,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "/demo",
        name: "demo",
        component: Demo,
        layout: "/app",
        prop: { exact: true }
    },
    {
        path: "*",
        layout: "",
        name: "404",
        component: _404
    }
];

export default indexRoutes;
