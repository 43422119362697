import React, { Component } from "react";
import Banner from "../../components/includes/Banner";

export class Search extends Component {
    render() {
        return (
            <div>
                <Banner mini />
                <div className="container">
                    <div className="row">
                        <div className="page-header mb-1 d-none" id="search-header">
                            <h1 className="page-title">
                                Arama Sonucu: {decodeURIComponent(this.props.location.search.replace("?q=", ""))}
                            </h1>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div id="searchResults"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Search;
