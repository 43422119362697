import React, { Component } from "react";

export class Success extends Component {
    componentDidMount() {
        localStorage.removeItem("cart-detail");
    }
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div style={{ fontSize: "8rem" }}>
                            <i className="fa fa-check-circle text-green-light"></i>
                        </div>
                        <div className="display-4 text-green-light my-3 font-weight-600">Siparişiniz Başarılı!</div>
                        <div className="h2 font-weight-light mb-7">Siparişiniz için teşekkür ederiz...</div>
                        <a href="/app/home">Anasayfaya Dön</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Success;
