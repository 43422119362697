import axios from "axios";

const ProductDetail = url => {
    return new Promise((resolve, reject) => {
        try {
            var x = localStorage.getItem("UniqueID");
            var target_sl = "";
            var vm_sl_params = "";
            var vm_cart, vm_invoice, vm_history, vm_id, vm_i, vm_c;

            var clearTarget = setInterval(() => {
                if (window.vm_history) {
                    vm_cart = window.vm_cart;
                    vm_invoice = window.vm_invoice;
                    vm_history = window.vm_history;
                    vm_id = window.vm_id;
                    vm_i = window.vm_i;
                    vm_c = window.vm_c;
                    target_sl = window.target_sl;

                    try {
                        if (vm_cart || vm_invoice) {
                            var vm_plist = vm_cart.length > 0 ? vm_cart : vm_invoice;
                            vm_sl_params = vm_sl_params + `&vm_cart=` + encodeURIComponent(JSON.stringify(vm_plist));
                        }
                    } catch (err) {
                        vm_sl_params = vm_sl_params + `&vm_cart=`;
                    }
                    try {
                        if (vm_history) {
                            vm_sl_params =
                                vm_sl_params +
                                `&vm_productcount=` +
                                vm_history.vm_productcount +
                                `&vm_productsum=` +
                                ~~vm_history.vm_productsum +
                                `&vm_history=` +
                                encodeURIComponent(JSON.stringify(vm_history.vm_products));
                        }
                    } catch (err) {}

                    vm_sl_params =
                        `oid=` +
                        vm_id +
                        `&i=` +
                        vm_i +
                        `&c=` +
                        vm_c +
                        vm_sl_params +
                        "&x=" +
                        x +
                        "&tid=22458&id=91&flag=1&stock=1&uri=" +
                        url;
                    clearInterval(clearTarget);
                    axios.post(target_sl, vm_sl_params).then(response => {
                        resolve(response);
                    });
                }
            }, 100);
        } catch (e) {}
    });
};

export { ProductDetail };
