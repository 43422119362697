import React, { Component } from "react";
import Banner from "../../components/includes/Banner";
import Categories from "../../components/Home/Categories";

export class Demo extends Component {
    render() {
        return (
            <>
                <Banner mini />
                <div className="container">
                    <div id="demodiv-1"></div>
                    <div id="demodiv-2"></div>
                    <div id="demodiv-3"></div>
                </div>
            </>
        );
    }
}

export default Demo;
