import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const categories = [
    {
        name: "food",
        icon: () => (
            <svg
                id="Iconspace_Hamburger_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M5.5,21a4.5,4.5,0,0,1-4.484-4.122A3.991,3.991,0,0,0,2,17H17a4.008,4.008,0,0,0,.984-.122A4.5,4.5,0,0,1,13.5,21ZM11,10h3.586l-1.793,1.793Zm7-1H.5A.5.5,0,0,1,0,8.5,8.51,8.51,0,0,1,8.5,0h2A8.51,8.51,0,0,1,19,8.5a.5.5,0,0,1-.5.5Z"
                    transform="translate(3 2)"
                    fill="#fff"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M10.586,0l3.207,3.207L17,0h1a3,3,0,0,1,0,6H3A3,3,0,0,1,3,0Z"
                    transform="translate(2 12)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Gıda",
        id: "5"
    },
    {
        name: "toy",
        icon: () => (
            <svg
                id="iconspace_Controller_3_25px"
                data-name="iconspace_Controller 3_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <rect id="Path" width="25" height="25" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M6.326,7.25H0A3.507,3.507,0,0,1,2.413,5.331V0h1.5V5.331A3.507,3.507,0,0,1,6.326,7.25Z"
                    transform="translate(9.337 7.75)"
                    fill="#fff"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M3.5,7A3.5,3.5,0,1,1,7,3.5,3.5,3.5,0,0,1,3.5,7Z"
                    transform="translate(9 2)"
                    fill="#fff"
                />
                <path
                    id="Path-4"
                    data-name="Path"
                    d="M2.5,0h16A2.5,2.5,0,0,1,21,2.5v3A1.5,1.5,0,0,1,19.5,7H1.5A1.5,1.5,0,0,1,0,5.5v-3A2.5,2.5,0,0,1,2.5,0Z"
                    transform="translate(2 16)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Oyuncak",
        id: "2"
    },
    {
        name: "school",
        icon: () => (
            <svg id="iconspace_Bag_25px" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M.75,17.25a.5.5,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5h6a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-.5.5ZM6,3.75a2.25,2.25,0,1,0-4.5,0,.75.75,0,0,1-1.5,0,3.75,3.75,0,1,1,7.5,0,.75.75,0,0,1-1.5,0Z"
                    transform="translate(8.75 2)"
                    fill="#fff"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M6.5,18A3.5,3.5,0,0,1,3,14.5v-8A6.5,6.5,0,0,1,9.5,0h2A6.5,6.5,0,0,1,18,6.5v8A3.5,3.5,0,0,1,14.5,18Zm12.245-2A4.5,4.5,0,0,0,19,14.5V9h.5A1.5,1.5,0,0,1,21,10.5v4A1.5,1.5,0,0,1,19.5,16ZM1.5,16A1.5,1.5,0,0,1,0,14.5v-4A1.5,1.5,0,0,1,1.5,9H2v5.5A4.478,4.478,0,0,0,2.256,16Z"
                    transform="translate(2 5.25)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Kırtasiye",
        id: "3"
    },
    {
        name: "diamond",
        icon: () => (
            <svg
                id="iconspace_Diamond_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="path-2"
                    data-name="path"
                    d="M0,1.023H5.715L9.758,16Zm14.843,0h5.693L10.856,15.88Zm-8.091,0h7.054l-3.516,13.1ZM15.043,0h0ZM6.476,0h0ZM.267,0h0Z"
                    transform="translate(2.233 7)"
                    fill="#fff"
                />
                <path
                    id="path-3"
                    data-name="path"
                    d="M.28,6h0Zm20.5-.541a.388.388,0,0,1,0,.079ZM15.137,5l2.6-4.522a.382.382,0,0,0,.026-.05L20.561,5ZM7.28,5l3-3.962L13.268,5ZM0,5,2.787.445l.018.033L5.409,5ZM10.725,0h6.134L14.215,4.627ZM3.684,0H9.833l-3.5,4.627Z"
                    transform="translate(2.221 2)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Takı & Mücevher",
        id: "4"
    },
    {
        name: "dress",
        icon: () => (
            <svg
                id="iconspace_Dress_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M1.5,21A1.5,1.5,0,0,1,0,19.5a1.476,1.476,0,0,1,.047-.372L2.533,9.407c.029-.113.054-.229.075-.344l1.469.49a12.834,12.834,0,0,0,4.846,0l1.445-.481a5.339,5.339,0,0,0,.139.729l2.444,9.319A1.5,1.5,0,0,1,11.5,21ZM4.393,8.6,2.687,8.035a4.982,4.982,0,0,0-.348-1.7L1.878,5.171a2.492,2.492,0,0,1-.152-1.258L2.2.432A.5.5,0,0,1,2.7,0H4.22A.5.5,0,0,1,4.7.363,1.876,1.876,0,0,0,7.02,1.651,1.877,1.877,0,0,0,8.309.363.5.5,0,0,1,8.789,0h1.523a.5.5,0,0,1,.5.432l.48,3.513a2.492,2.492,0,0,1-.126,1.188l-.507,1.4a5.492,5.492,0,0,0-.314,1.49L8.607,8.6a8.884,8.884,0,0,1-2.107.18A8.893,8.893,0,0,1,4.393,8.6Z"
                    transform="translate(6 2)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Kadın Giyim",
        id: "1"
    },
    {
        name: "healthly",
        icon: () => (
            <svg
                id="iconspace_Syrup_Medicine_25px"
                data-name="iconspace_Syrup Medicine_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M15.5,20H3.5A3.5,3.5,0,0,1,0,16.5v-6A4.505,4.505,0,0,1,4.5,6H6V5h7V6h1.5A4.505,4.505,0,0,1,19,10.5v6A3.5,3.5,0,0,1,15.5,20Zm-9-8a.5.5,0,0,0,0,1H9v2.5a.5.5,0,1,0,1,0V13h2.5a.5.5,0,0,0,0-1H10V9.5a.5.5,0,1,0-1,0V12Zm7-8h-8A1.5,1.5,0,0,1,4,2.5v-1A1.5,1.5,0,0,1,5.5,0h8A1.5,1.5,0,0,1,15,1.5v1A1.5,1.5,0,0,1,13.5,4Z"
                    transform="translate(3 3)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Sağlık ve Bakım",
        id: "6"
    }
    /* {
        name: "art",
        icon: () => (
            <svg
                id="iconspace_Gallery_25px"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25">
                <path id="Path" d="M0,0H25V25H0Z" fill="none" />
                <path
                    id="Path-2"
                    data-name="Path"
                    d="M0,3.5A3.5,3.5,0,0,1,3.5,0h14A3.5,3.5,0,0,1,21,3.5V13L15.854,7.853a.5.5,0,0,0-.707,0l-4.772,4.772-2.5-2.752a.5.5,0,0,0-.725-.02L0,17Z"
                    transform="translate(2 2)"
                    fill="#fff"
                />
                <path
                    id="Path-3"
                    data-name="Path"
                    d="M1,2A1,1,0,1,1,2,1,1,1,0,0,1,1,2Z"
                    transform="translate(8 6)"
                    fill="#fff"
                />
                <path
                    id="Path-4"
                    data-name="Path"
                    d="M16.334,12H3.366A3.517,3.517,0,0,1,0,9.5l7.377-7.45L16.333,12Zm1.356-.006h0L10.972,4.531,15.458,0,21,5.594V8.438a3.55,3.55,0,0,1-3.309,3.555Z"
                    transform="translate(2 11)"
                    fill="#fff"
                />
            </svg>
        ),
        text: "Sanat ve Müze",
        id: "7"
    } */
];
export class Banner extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            variableWidth: true,
            arrows: false,
            swipeToSlide: true
        };
        const { mini } = this.props;
        return (
            <div className="banner-header" style={{ height: mini ? "15rem" : "30rem" }}>
                <span
                    id="bannerImage"
                    className="w-100 h-100 position-absolute"
                    style={{ zIndex: 3, backgroundColor: "rgba(0,0,0,.15)" }}></span>
                <div className="container" style={{ zIndex: 5 }}>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-10">
                            <input
                                type="search"
                                className="form-control header-search form-control-lg "
                                placeholder="Ne duruyorsun, hemen ara..."
                                tabIndex="1"
                                id="searchBar"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-10" id="categorySliderWrapper">
                            <Slider {...settings}>
                                {categories
                                    .sort((x, y) => (x.id > y.id ? 1 : -1))
                                    .map((el, key) => {
                                        return (
                                            <div id="categorySlider" key={key.toString()}>
                                                <a
                                                    onDragStart={e => e.preventDefault()}
                                                    href={"/app/category/" + el.id}
                                                    className={`card mb-0 card-link bg-dark hover-${el.name} cursor-pointer`}>
                                                    <div className="card-body py-2 px-3 d-flex justify-content-center align-items-center">
                                                        <span className="category-icon mr-2">{el.icon()}</span>
                                                        <h5 className="d-inline-block text-muted">{el.text}</h5>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;
